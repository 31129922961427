import * as React from "react";

function SvgMoney(props) {
  return (
<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.3458 0.157495C29.505 -0.459434 31.7556 0.790865 32.3725 2.95012C32.4763 3.31339 32.529 3.68936 32.529 4.06717C32.529 5.94709 34.0529 7.47107 35.9329 7.47107H36.5951C38.8407 7.47107 40.6612 9.29153 40.6612 11.5372V35.9339C40.6612 38.1796 38.8407 40 36.5951 40H4.06612C1.82882 40 0.0135526 38.1931 7.55324e-05 35.9589C-7.49109e-06 35.9452 0.0111573 35.9339 0.0249206 35.9339C0.0403044 35.9339 0.052009 35.9201 0.0496132 35.9049C0.0166276 35.6957 0 35.484 0 35.2717V11.0377C0 9.22221 1.20348 7.62672 2.94907 7.12798L27.3458 0.157495ZM29.5799 32.2108C27.4636 32.8155 27.9003 35.9339 30.1013 35.9339H34.1325C35.4925 35.9339 36.5951 34.8313 36.5951 33.4713L36.5951 21.7025C36.5951 20.5797 35.6848 19.6694 34.562 19.6694C33.4392 19.6694 32.529 20.5797 32.529 21.7025V28.3012C32.529 30.1166 31.3255 31.7121 29.5799 32.2108ZM34.562 11.5372C35.6849 11.5372 36.5951 12.4474 36.5951 13.5703C36.5951 14.6931 35.6848 15.6033 34.562 15.6033C33.4392 15.6033 32.529 14.6931 32.529 13.5702C32.529 12.4474 33.4392 11.5372 34.562 11.5372ZM12.5162 8.6233C7.51449 10.0524 4.06612 14.624 4.06612 19.8258C4.06612 27.5663 11.4749 33.1548 18.9176 31.0283L20.0128 30.7154C25.0145 29.2864 28.4628 24.7148 28.4628 19.5129C28.4628 11.7724 21.0541 6.18391 13.6113 8.3104L12.5162 8.6233ZM24.3967 15.6033C24.3967 16.7261 23.4865 17.6364 22.3637 17.6364C21.2408 17.6364 20.3306 16.7261 20.3306 15.6033C20.3306 14.4805 21.2408 13.5703 22.3637 13.5703C23.4865 13.5703 24.3967 14.4805 24.3967 15.6033Z" fill="#61788E"/>
</svg>

  );
}

export default SvgMoney;
