import * as React from "react";

function SvgUser(props) {
  return (
<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5C45 34.9264 34.9264 45 22.5 45ZM27.5736 28.8775C33.0444 29.4484 40.9099 28.0003 40.9099 22.4998C40.9099 12.3328 32.6678 4.09074 22.5008 4.09074C12.3337 4.09074 4.09169 12.3328 4.09169 22.4998C4.09169 27.9994 11.9536 29.4483 17.4235 28.8775C18.9837 28.7146 20.6807 28.6364 22.4985 28.6364C24.3163 28.6364 26.0134 28.7146 27.5736 28.8775ZM23.3452 40.8899C30.9808 40.5452 30.9462 32.8455 23.3036 32.7331C23.0394 32.7293 22.771 32.7273 22.4985 32.7273C22.234 32.7273 21.9735 32.7291 21.7169 32.7328C14.0639 32.8419 14.0251 40.5515 21.6714 40.8906C21.9463 40.9028 22.2228 40.9089 22.5008 40.9089C22.7838 40.9089 23.0653 40.9025 23.3452 40.8899ZM22.4992 10.2272C17.5474 10.2272 14.3174 13.8185 14.3174 18.409C14.3174 25.4195 17.9191 28.6363 22.4992 28.6363C27.0362 28.6363 30.681 25.5264 30.681 18.8181C30.681 14.1577 27.4373 10.2272 22.4992 10.2272ZM18.4083 18.4091C18.4083 23.0507 20.0819 24.5454 22.4992 24.5454C24.9082 24.5454 26.5901 23.1103 26.5901 18.8182C26.5901 16.2622 24.9858 14.3182 22.4992 14.3182C19.9091 14.3182 18.4083 15.9869 18.4083 18.4091Z" fill="#61788E"/>
</svg>

  );
}

export default SvgUser;
