import * as React from "react";

function SvgMortarboard(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 512 512"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      {...props}
    >
      <path d="M501.991 128.354l-241-85.031a14.992 14.992 0 00-9.981 0l-241 85.031a15.001 15.001 0 00-.034 28.279l241 85.677a14.991 14.991 0 0010.048 0l241-85.677a15 15 0 00-.033-28.279zM475.973 328.574v-130.84l-30 10.665v120.175c-9.036 5.201-15.125 14.946-15.125 26.121 0 11.174 6.089 20.92 15.125 26.121v73.716c0 8.284 6.716 15 15 15s15-6.716 15-15v-73.715c9.036-5.2 15.125-14.947 15.125-26.121 0-11.175-6.088-20.921-15.125-26.122z" />
      <path d="M256 273.177c-5.149 0-10.22-.875-15.073-2.6l-135.483-48.165v66.008c0 16.149 16.847 29.806 50.073 40.59 28.961 9.4 64.647 14.577 100.483 14.577s71.521-5.177 100.483-14.577c33.226-10.784 50.073-24.441 50.073-40.59v-66.008l-135.482 48.165a44.896 44.896 0 01-15.074 2.6z" />
    </svg>
  );
}

export default SvgMortarboard;
