import * as React from "react";

function SvgClock(props) {
  return (
<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5C45 34.9264 34.9264 45 22.5 45ZM22.5 40.9091C32.6671 40.9091 40.9091 32.6671 40.9091 22.5C40.9091 12.3329 32.6671 4.09091 22.5 4.09091C12.3329 4.09091 4.09091 12.3329 4.09091 22.5C4.09091 32.6671 12.3329 40.9091 22.5 40.9091ZM32.7273 22.5C32.7273 21.3703 31.8115 20.4545 30.6818 20.4545H28.6364C26.377 20.4545 24.5455 18.623 24.5455 16.3636V12.2727C24.5455 11.1431 23.6297 10.2273 22.5 10.2273C21.3703 10.2273 20.4545 11.1431 20.4545 12.2727V18.4091C20.4545 21.7981 23.2019 24.5455 26.5909 24.5455H30.6818C31.8115 24.5455 32.7273 23.6297 32.7273 22.5Z" fill="#61788E"/>
</svg>

  );
}

export default SvgClock;
